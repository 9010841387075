@font-face {
  font-family: 'NunitoSans';
  src: url('./NunitoSans/NunitoSans-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./NunitoSans/NunitoSans-ExtraBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./NunitoSans/NunitoSans-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./NunitoSans/NunitoSans-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./NunitoSans/NunitoSans-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./NunitoSans/NunitoSans-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./NunitoSans/NunitoSans-ExtraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}